import styled from 'styled-components';

export const MenuItemContainer = styled.div<{
  size?: 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary';
}>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${({ size }) => {
    switch (size) {
      case 'small':
        return '0.8rem 0.8rem 0.8rem 1.2rem';
      case 'large':
        return '2rem 2.8rem 2rem 2rem';
      case 'medium':
        return '1.6rem 2.8rem 1.6rem 2rem';
      default:
        return '1.6rem 2.8rem 1.6rem 2rem';
    }
  }};
  gap: 0.8rem;
  min-width: ${({ size }) => (size !== 'small' ? ' 32.8rem' : 'auto')};
  text-align: left;
  background: ${({ variant, theme }) => {
    if (variant === 'primary') {
      return 'white';
    }
    if (variant === 'secondary') {
      return theme.colors.white;
    }
    return 'transparent';
  }};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  color: ${({ theme }) => theme.colors.navy};
  border-radius: inherit;
  transition: all 0.3s ease;
  &:hover {
    background: ${({ theme }) => theme.colors.lightGreen};
    color: ${({ theme }) => theme.colors.green};
    border-radius: 0;
    & * {
      color: ${({ theme }) => theme.colors.green};
      fill: ${({ theme }) => theme.colors.green};
    }
  }
`;

import React from 'react';
import { MenuItemContainer } from './MenuItem.style';

interface Props {
  children: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary';
}

const MenuItem = ({
  children,
  size = 'medium',
  variant = 'primary',
}: Props) => {
  return (
    <MenuItemContainer size={size} variant={variant}>
      {children}
    </MenuItemContainer>
  );
};

export default MenuItem;

import React from 'react';

const Telephone = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.72 6.66667C8.8 7.85333 9 9.01333 9.32 10.12L7.72 11.72C7.17333 10.12 6.82667 8.42667 6.70667 6.66667H8.72ZM21.8667 22.6933C23 23.0133 24.16 23.2133 25.3333 23.2933V25.28C23.5733 25.16 21.88 24.8133 20.2667 24.28L21.8667 22.6933ZM10 4H5.33333C4.6 4 4 4.6 4 5.33333C4 17.8533 14.1467 28 26.6667 28C27.4 28 28 27.4 28 26.6667V22.0133C28 21.28 27.4 20.68 26.6667 20.68C25.0133 20.68 23.4 20.4133 21.9067 19.92C21.7733 19.8667 21.6267 19.8533 21.4933 19.8533C21.1467 19.8533 20.8133 19.9867 20.5467 20.24L17.6133 23.1733C13.84 21.24 10.7467 18.16 8.82667 14.3867L11.76 11.4533C12.1333 11.08 12.24 10.56 12.0933 10.0933C11.6 8.6 11.3333 7 11.3333 5.33333C11.3333 4.6 10.7333 4 10 4Z"
        fill="#1A2136"
      />
    </svg>
  );
};

export default Telephone;

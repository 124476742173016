import React from 'react';
import Navbar from 'components/commons/Navbar/Navbar';
import { LoanOfficer } from 'src/common/types';
import Footer from '../Footer/Footer';

const Layout = ({
  children,
  officer,
  showNavbarLinks = true,
}: {
  children: React.ReactNode;
  officer?: LoanOfficer;
  showNavbarLinks?: boolean;
}) => {
  return (
    <>
      <Navbar officer={officer} showLinks={showNavbarLinks} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;

import React from 'react';
import sendGaEvent from 'src/utils/gtag';
import { useFormMachine } from 'src/context/FormMachineProvider';
import { GaPage, GaEvent, LoanOfficer } from 'src/common/types';
import { SecondaryButton, PrimaryButton } from 'components/lib';

interface ApplyNowButtonProps {
  children: string;
  gaEvent?: GaEvent;
  gaPage?: GaPage;
  testID?: string;
  as?: 'primary' | 'secondary';
  className?: string;
  fluid?: boolean;
  lo?: LoanOfficer;
}

const ApplyNowButton = ({
  children,
  gaEvent = 'clickedApplyNow',
  gaPage = 'shared',
  testID = '',
  as = 'primary',
  className = '',
  fluid = false,
  lo,
}: ApplyNowButtonProps) => {
  const [, send] = useFormMachine();

  const Button = as === 'primary' ? PrimaryButton : SecondaryButton;

  const handleBeginFlow = () => {
    sendGaEvent(gaEvent, gaPage);
    if (lo) {
      send({
        type: 'REMOVE_AGENT',
      });
      send({
        type: 'SET_LO',
        loanManagerId: lo.kwid,
        loanManagerFirstName: lo.firstName || '',
        loanManagerLastName: lo.lastName || '',
        loanManagerEmail: lo.email || '',
        loanManagerPhoto: lo.photo || '',
      });
      send({
        type: 'START_FLOW',
        campaignId: 61,
        partnerId: 102100,
        aba: 'on',
      });
    } else {
      send('START_FLOW');
    }
  };

  return (
    <Button
      className={className}
      onClick={handleBeginFlow}
      data-testid={testID}
      data-cy="apply"
      fluid={fluid}
      role="button"
    >
      {children}
    </Button>
  );
};

export default ApplyNowButton;

import React from 'react';

const ArrowBottom = ({
  width = 11,
  height = 8,
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 8"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.15801 0.912598L5.33301 4.72926L1.50801 0.912598L0.333008 2.0876L5.33301 7.0876L10.333 2.0876L9.15801 0.912598Z" />
    </svg>
  );
};

export default ArrowBottom;

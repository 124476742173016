import LoginIcon from 'components/commons/svg/LoginIcon';

export const navbarLang = {
  topMenu: {
    contact: {
      label: '1 (833) 429-8733',
      href: 'tel:+1 (833) 429-8733',
    },
    forBorrowers: {
      label: 'For Borrowers',
      options: [
        {
          label: 'Share documents',
          href: 'https://apply.kellermortgage.com/#/',
        },
        {
          label: 'Make a payment',
          href: 'https://53.billerdirectexpress.com/ebpp/Kellermrtg/',
        },
      ],
    },
    forAgents: {
      label: 'For Agents',
      options: [
        {
          label: 'Agent Sign-in',
          href: 'https://www.myagentstation.com/',
          target: '_blank',
          Icon: <LoginIcon />,
        },
        {
          label: 'About Agent Station',
          target: '_blank',
          href: '/agent-station',
        },
      ],
    },
  },
  navBar: {
    findLoan: {
      label: 'Connect with a loan officer',
      href: '/loan-officers',
    },
    loanOfferings: { label: 'Loan offerings', href: '/loan-offerings' },
    /* careers: {
      label: 'Careers',
      href: 'https://info.kellermortgage.com/careers',
    }, */
  },
};

import { H6, H7 } from 'components/lib';
import styled from 'styled-components';

export const DropdownMenuContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 88px;
  left: ${({ isOpen }) => (isOpen ? '0' : '-100vw')};
  z-index: 1;
  width: 100%;
  border-radius: 4px;
  padding: 12px 16px;
  margin-top: -1px;
  transition: left 0.3s ease-out;
  background-color: ${({ theme }) => theme.colors.lightGray};
  min-height: calc(100vh - 88px);
  height: 100%;
  display: none;
  overflow-y: scroll;
  .main-nav {
    .links {
      width: 100%;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 2.4rem;
  }
  .top-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.6rem;
    .actions {
      width: 100%;
    }
    .for-container {
      ${H7} {
        color: ${({ theme }) => theme.colors.green};
        padding: 1rem 2rem;
      }
      ${H6} {
        color: ${({ theme }) => theme.colors.lightNavy};
      }
      width: 100%;
    }
  }

  .apply {
    align-self: stretch;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1.6rem;
    align-items: center;
  }
`;

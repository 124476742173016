import React from 'react';

const ArrowRight = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="8.47998"
        y="15.3047"
        width="9.50245"
        height="2"
        transform="rotate(-45 8.47998 15.3047)"
        fill="currentColor"
      />
      <rect
        x="15.1982"
        y="11.4121"
        width="9.50368"
        height="2"
        transform="rotate(-135 15.1982 11.4121)"
        fill="currentColor"
      />
      <rect x="3.25" y="9" width="12" height="2" fill="currentColor" />
    </svg>
  );
};

export default ArrowRight;

import styled from 'styled-components';
import { Container, Link } from 'components/lib';

export const TobBar = styled(Container)`
  display: none;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.4rem 5.2rem;
  .links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3.2rem;
    ${Link} {
      font-size: 12px;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.lightNavy};
      :hover {
        color: ${({ theme }) => theme.colors.green};
      }
    }
    .login {
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        fill: currentColor;
        transition: fill 0.2s ease-in-out;
        margin-left: 4px;
      }
      &:hover {
        svg {
          fill: ${({ theme }) => theme.colors.green};
        }
      }

      &:after {
        width: 77%;
        bottom: -1.5px;
      }
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
  }
`;

export const DropDown = styled.div`
  position: relative;
  .agent {
    @media (max-width: ${({ theme }) => theme.breakpoints.xxxl}) {
      margin-left: -10.9rem !important;
    }
  }
  .dropdown-content {
    align-items: flex-start;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 8px;
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.05);
    flex-direction: column;
    justify-content: center;
    margin-top: 0.8rem;
    min-width: 21.9rem;
    opacity: 0;
    padding: 0px;
    position: absolute;
    transition: all 0.3s ease-in-out;
    visibility: hidden;
    z-index: 5;
    .option {
      align-items: center;
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
      border-radius: 0px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: 8px 8px 8px 12px;
      width: 100%;
      svg {
        path {
          fill: ${({ theme }) => theme.colors.navy};
          transition: fill 0.2s ease-in-out;
        }
        rect {
          fill: ${({ theme }) => theme.colors.navy};
          transition: rect 0.2s ease-in-out;
        }
      }
      a {
        font-family: 'Proxima-Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: ${({ theme }) => theme.colors.navy};
        text-decoration: none;
        transition: color 0.2s ease-in-out;
        white-space: nowrap;
      }
      &:hover {
        a {
          color: ${({ theme }) => theme.colors.green};
        }

        svg {
          path {
            fill: ${({ theme }) => theme.colors.green};
          }
          rect {
            fill: ${({ theme }) => theme.colors.green};
          }
        }
      }
    }
  }
  &:hover,
  &:focus-within {
    .dropdown-content {
      opacity: 1;
      visibility: visible;
    }
    ${Link} {
      color: ${({ theme }) => theme.colors.green};

      &::after {
        height: 1px;
        background-color: ${({ theme }) => theme.colors.lightGreen};
      }
    }
  }
`;

export const NavbarContainer = styled(Container)`
  position: sticky;
  top: 44px;
  left: 0;
  right: 0;
  z-index: 4;
  transition: top 0.2s ease-in-out;
  &.fixed-top {
    top: 0px;
  }
  .inner {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 1.6rem;
    box-shadow: ${({ theme }) => theme.shadows.default};
    display: flex;
    justify-content: space-between;
    padding: 20px 24px;
    width: 100%;
    z-index: 3;
    .logo {
      height: 48px;
      svg {
        width: 200px;
        height: 48px;
      }
    }

    .links {
      display: flex;
      align-items: center;

      & > a:not(:last-child) {
        margin-right: 32px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0;
    margin: 0 1.6rem;
    .inner {
      .links {
        display: none;
      }
    }
  }
`;

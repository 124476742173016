import React from 'react';
import ApplyNowButton from 'components/commons/ApplyButton/ApplyButton';
import { H6, Link } from 'components/lib';
import NextLink from 'next/link';
import { navbarLang } from 'src/data/lang/navbar.lang';
import { LoanOfficer } from 'src/common/types';
import { useInView } from 'react-intersection-observer';
import DropdownMenu from './DropdownMenu/DropdownMenu';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';
import { DropDown, NavbarContainer, TobBar } from './navbar-styles';
import ArrowBottom from '../svg/ArrowBottom';
import MenuItem from './MenuItem/MenuItem';
import ArrowRight from '../svg/ArrowRight';
import LogoKellerHome from '../svg/LogoKellerHome';

interface NavbarProps {
  officer?: LoanOfficer;
  showLinks?: boolean;
}

const Navbar = ({ officer, showLinks = true }: NavbarProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { firstName } = officer || {};
  const [topBarRef, inView] = useInView({
    threshold: 0,
  });
  const { contact, forBorrowers, forAgents } = navbarLang.topMenu;
  const { findLoan, loanOfferings } = navbarLang.navBar;

  return (
    <>
      <TobBar ref={topBarRef}>
        <div className="breadcrumb" />
        <div className="location" />
        {showLinks ? (
          <div className="links">
            <Link href={contact.href}>{contact.label}</Link>
            <DropDown tabIndex={0}>
              <Link as="label">
                {forBorrowers.label}
                <span style={{ paddingLeft: '6px' }}>
                  <ArrowBottom />
                </span>
              </Link>
              <div className="dropdown-content">
                {forBorrowers.options.map(option => (
                  <NextLink href={option.href} key={option.label}>
                    <MenuItem size="small" variant="primary">
                      <H6>{option.label}</H6>
                      <ArrowRight />
                    </MenuItem>
                  </NextLink>
                ))}
              </div>
            </DropDown>
            <DropDown tabIndex={0}>
              <Link as="label">
                {forAgents.label}
                <span style={{ paddingLeft: '6px' }}>
                  <ArrowBottom />
                </span>
              </Link>
              <div className="dropdown-content agent">
                {forAgents.options.map(option => (
                  <NextLink
                    href={option.href}
                    key={option.label}
                    target={option.target || '_self'}
                  >
                    <MenuItem size="small" variant="primary">
                      <H6>{option.label}</H6>
                      {option.Icon || <ArrowRight />}
                    </MenuItem>
                  </NextLink>
                ))}
              </div>
            </DropDown>
          </div>
        ) : null}
      </TobBar>
      <NavbarContainer className={`${inView ? '' : 'fixed-top'}`}>
        <nav className="inner">
          <div className="logo">
            <NextLink href="/" passHref aria-label="Keller Mortgage Home">
              <LogoKellerHome />
            </NextLink>
          </div>
          {showLinks ? (
            <>
              <div className="links">
                <NextLink href={findLoan.href} legacyBehavior>
                  <Link href={findLoan.href}>{findLoan.label}</Link>
                </NextLink>
                <NextLink href={loanOfferings.href} legacyBehavior>
                  <Link href={loanOfferings.href}>{loanOfferings.label}</Link>
                </NextLink>
                {/* <Link href={careers.href} target="_blank">
                  {careers.label}
          </Link> */}
                <ApplyNowButton as="secondary" lo={officer}>
                  {firstName ? `Apply with ${firstName}` : `Apply Now`}
                </ApplyNowButton>
              </div>
              <HamburgerMenu isOpen={isOpen} setIsOpen={setIsOpen} />
            </>
          ) : null}
        </nav>
        <DropdownMenu isOpen={isOpen} />
      </NavbarContainer>
    </>
  );
};

export default Navbar;

import ApplyNowButton from 'components/commons/ApplyButton/ApplyButton';
import LongArrowRight from 'components/commons/svg/LongArrowRight';
import Telephone from 'components/commons/svg/socials/Telephone';
import { H5, H6, H7 } from 'components/lib';
import NextLink from 'next/link';
import { navbarLang } from 'src/data/lang/navbar.lang';
import MenuItem from '../MenuItem/MenuItem';
import { DropdownMenuContainer } from './dropdown-styles';

type DropdownMenuProps = {
  isOpen: boolean;
};

const DropdownMenu = ({ isOpen }: DropdownMenuProps) => {
  const { findLoan, loanOfferings } = navbarLang.navBar;
  const { contact, forAgents, forBorrowers } = navbarLang.topMenu;

  return (
    <DropdownMenuContainer isOpen={isOpen}>
      <div className="main-nav">
        <div className="links">
          <NextLink href={findLoan.href}>
            <MenuItem size="large" variant="primary">
              <H5>{findLoan.label}</H5>
              <LongArrowRight />
            </MenuItem>
          </NextLink>
          <NextLink href={loanOfferings.href}>
            <MenuItem size="large" variant="primary">
              <H5>{loanOfferings.label}</H5>
              <LongArrowRight />
            </MenuItem>
          </NextLink>
          {/* <NextLink href={careers.href} target="_blank" rel="referrer">
            <MenuItem size="large" variant="primary">
              <H5>{careers.label}</H5>
              <LongArrowRight />
            </MenuItem>
  </NextLink> */}
        </div>
        <ApplyNowButton as="primary" className="apply">
          Apply Now
        </ApplyNowButton>
      </div>
      <div className="top-menu">
        <div className="actions">
          <NextLink href={contact.href}>
            <MenuItem size="medium" variant="secondary">
              <H6>{contact.label}</H6>
              <Telephone />
            </MenuItem>
          </NextLink>
        </div>
        <div className="for-container">
          <H7>{forAgents.label}</H7>
          {forAgents.options.map(option => (
            <NextLink
              href={option.href}
              key={option.label}
              target={option.target || '_self'}
              rel="referrer"
            >
              <MenuItem variant="secondary" size="medium">
                <H6>{option.label}</H6>
                {option.Icon || <LongArrowRight />}
              </MenuItem>
            </NextLink>
          ))}
        </div>
        <div className="for-container">
          <H7>{forBorrowers.label}</H7>
          {forBorrowers.options.map(option => (
            <NextLink
              href={option.href}
              key={option.label}
              target="_blank"
              rel="referrer"
            >
              <MenuItem variant="secondary" size="medium">
                <H6>{option.label}</H6>
                <LongArrowRight />
              </MenuItem>
            </NextLink>
          ))}
        </div>
      </div>
    </DropdownMenuContainer>
  );
};

export default DropdownMenu;
